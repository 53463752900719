import { Controller } from "stimulus"
let doorInitialWidth = null
let doorInitialHeight = null
let resizeInitialHeight = null
let doorWidth = null
let doorHeight = null
let doorContext = null
let canvasUp = null
let canvasLeft = null
let doorCanvas = null
let doorCanvasContainer = null
let canvasLeftContainer = null
let canvasUpContainer = null
let doorHinges = null
let side = null
export default class extends Controller {

  connect() {
    console.log('DoorWorkOrderController')
    let previews = document.querySelectorAll('.supervisor-preview')
    previews.forEach((preview) => {
      if (preview) {
        canvasUp = preview.querySelector('#canvasUp')  
        canvasLeft = preview.querySelector('#canvasLeft')
        doorCanvas = preview.querySelector('#doorCanvas')
        doorCanvasContainer = preview.querySelector('.canvas')
        canvasLeftContainer = preview.querySelector('.canvas-left')
        canvasUpContainer = preview.querySelector('.canvas-up')
        doorInitialWidth = Number(preview.dataset.base)
        doorInitialHeight = Number(preview.dataset.height)
        doorHinges = JSON.parse(preview.dataset.hinges)
        side = preview.dataset.side
        doorWidth = doorReSizeWidth(doorInitialWidth - 10)
        doorHeight = doorReSizeHeight(doorInitialHeight)
        resizeInitialHeight = doorHeight
        doorCanvas.width = doorCanvasContainer.offsetWidth - canvasLeftContainer.offsetWidth
        doorCanvas.height = doorCanvasContainer.offsetHeight
        canvasUp.width = doorCanvasContainer.offsetWidth - canvasLeftContainer.offsetWidth
        canvasUp.height = doorCanvasContainer.offsetHeight;
        canvasLeft.height = canvasLeftContainer.offsetHeight;
        canvasLeft.width = canvasLeftContainer.offsetWidth;
        doorContext = doorCanvas.getContext("2d")
        doorContext.lineWidth = 2.5
        drawDoor()
        doorDrawHinges()
        drawMeasuresLines();
        
      }
    })
  }
}

const doorReSizeWidth = ( value ) => {
  let width = doorCanvasContainer.offsetWidth - canvasLeftContainer.offsetWidth - 20
  let height = doorCanvasContainer.offsetHeight
  let proportion = doorInitialHeight > doorInitialWidth ? doorInitialHeight / height : doorInitialWidth / width
  let new_value = proportion > 1 ? value / proportion : value * proportion
  return new_value
}

const doorReSizeHeight = ( value ) => {
  let height = doorCanvasContainer.offsetHeight
  let width = doorCanvasContainer.offsetWidth - canvasLeftContainer.offsetWidth
  let proportion = doorInitialHeight > doorInitialWidth ? value / height : height / width
  let new_value = proportion > 1 ? value / proportion : value * proportion
  if(new_value > height){
    new_value = value / (value / height)
  }
  return new_value
}

// Draw Doors
// 
// Draw the lines of each door in the canvas with the door size of the quote
const drawDoor = () => {
  doorDrawLine(0, 0, 0, doorHeight)
  doorDrawLine(0, doorHeight, doorWidth, doorHeight)
  doorDrawLine(doorWidth, doorHeight, doorWidth, 0)
  doorDrawLine(doorWidth, 0, 0, 0)
}

// Draw doorHinges
// 
// Draw the doorHinges with the position in the door
const doorDrawHinges = () => {
  let positionX,positionY,positionText, measureX, measureY = 0;
  doorContext.beginPath()
  doorHinges.forEach((hinge, index) => {
    switch (side) {
      case 'Left':
        positionX = 20
          if(index+1 === doorHinges.lenght || hinge['section'] == 'E'){
            positionY = doorHeight - doorReSizeWidth(Number(hinge['value']))
            positionText = doorInitialHeight - Number(hinge['value'])
          }else{
            positionY = Number(hinge['value'])
            positionText = Number(hinge['value'])
            positionY = doorReSizeWidth(positionY)
          }
        measureY = positionY+5
        measureX = positionX+24
        break;
      case 'Right':
        positionX = doorWidth - 20
        if(hinge['section'] == 'E'){
          positionY = doorHeight - doorReSizeWidth(Number(hinge['value']))
          positionText = doorInitialHeight - Number(hinge['value'])
        }else{
          positionY = Number(hinge['value'])
          positionText = Number(hinge['value'])
          positionY = doorReSizeWidth(positionY)
        }
        measureY = positionY+5
        measureX = positionX-24
        break;  
      case 'Top':
        positionY = 20
          if(hinge['section'] == 'E'){
            positionX = doorWidth - doorReSizeWidth(Number(hinge['value']))
            positionText = doorInitialWidth - Number(hinge['value'])
          }else{
            positionX = Number(hinge['value'])
            positionText = Number(hinge['value'])
            positionX = doorReSizeWidth(positionX)
          }
        measureX = positionX
        measureY = positionY+24
        break;
      case 'Bottom':
        positionY = doorHeight - 20
          if(hinge['section'] == 'E'){
            positionX = doorWidth - doorReSizeWidth(Number(hinge['value']))
            positionText = doorInitialWidth - Number(hinge['value'])
          }else{
            positionX = Number(hinge['value'])
            positionText = Number(hinge['value'])
            positionX = doorReSizeWidth(positionX)
          }
        measureX = positionX
        measureY = positionY-18
        break;
      case 'Avento':
        break;
      default:
        break;
    }
    doorContext.moveTo(positionX+5,positionY)
    doorContext.arc(positionX, positionY, 5, 0, Math.PI * 2, true);
    doorDrawMeasure(measureX, measureY, positionText)
  })
  
  doorContext.stroke();
}

const doorDrawCircle = (x,y) => {
  // doorContext.beginPath();
  doorContext.moveTo(x,y)
  doorContext.arc(x, y, 5, 0, Math.PI * 2, true);
  doorContext.stroke();
}

const doorDrawMeasure = (positionX, positionY, text) => {
  doorContext.textAlign = 'center'
  doorContext.font = `14px monospace`
  doorContext.fillText(text, positionX, positionY)
}

const doorDrawLine = (x1, y1, x2, y2) => {
  doorContext.moveTo(x1, y1);
  doorContext.lineTo(x2, y2);
  doorContext.stroke();
}

const drawLineCanvas = (container, x1, y1, x2, y2, strokeSize) => {
  let drawContext = container.getContext('2d')
  drawContext.lineWidth = strokeSize
  drawContext.moveTo(x1, y1);
  drawContext.lineTo(x2, y2);
  drawContext.stroke();
}


const drawMeasuresCanvas = (container, xAxis, yAxis, text) => {
  let drawContext = container.getContext('2d')
  drawContext.textAlign = 'center'
  drawContext.font = `14px monospace`
  drawContext.fillText(text, xAxis, yAxis)
}

const drawMeasuresLines = () => {

  let heightText = doorInitialHeight
  // Draw  Width lines with measures in the midle
  drawLineCanvas(canvasUp, 0, 10, 0, 30, 3)
  drawLineCanvas(canvasUp, 0, 20, (doorWidth/2)-40, 20, 3)
  drawLineCanvas(canvasUp, doorWidth, 10, doorWidth, 30, 3)
  drawLineCanvas(canvasUp, (doorWidth/2)+40, 20, doorWidth, 20, 3)
  drawMeasuresCanvas(canvasUp, (doorWidth/2), 27, doorInitialWidth)

  // Draw  Hegiht lines with measures in the midle
  drawLineCanvas(canvasLeft, 10, 0, 30, 0, 3)
  drawLineCanvas(canvasLeft, 20, 0, 20, (resizeInitialHeight/2)-20, 3)
  drawLineCanvas(canvasLeft, 20, (resizeInitialHeight/2)+20, 20, resizeInitialHeight, 3)
  drawLineCanvas(canvasLeft, 10, resizeInitialHeight, 30, resizeInitialHeight, 3)
  drawMeasuresCanvas(canvasLeft, 16, (resizeInitialHeight/2)+5, doorInitialHeight)
}
