import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  print(){
    window.print()
  }
}

document.addEventListener("turbolinks:load", function () {
  let rows = Array.prototype.slice.call(document.querySelectorAll('.clickeable-row'))
  rows.forEach((row)=>{
    row.addEventListener('click', () => {
      let url = row.dataset.url
      Turbolinks.visit(url)
    })
  })
});