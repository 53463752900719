
import { Controller } from "@hotwired/stimulus"
let selectedRailings = []
export default class extends Controller {
  static targets = [ "railing", "railings" ]

  connect () {
    this.railingTargets.forEach((railing) => {
      let railingSelected = this.railingsTarget.dataset.railings.split(',').includes(railing.dataset.sku)
      railing.checked = railingSelected
    })
  }

  select(event) {
    let railingSku = event.target.dataset.sku
    let projectFamily = event.target.dataset.family
    let railingQuantity = projectFamily == 'MADERA ACORDEON' ? 1 : 2;
    if ( event.target.checked ){
      selectedRailings.push(railingSku)
    } else {
      selectedRailings = selectedRailings.filter( r => r !== railingSku)
    }

    this.railingTargets.forEach((railing) => {
      if (selectedRailings.length >= railingQuantity ) {
        let selected = selectedRailings.includes(railing.dataset.sku)
        if (!selected ) {railing.parentNode.classList.add('disabled')}
      } else {
        railing.parentNode.classList.remove('disabled')
      }
    })
  }

  next(event) {
    let url = `./${selectedRailings.join(',')}/#step-additionals`
    let projectFamily = event.target.dataset.family
    let railingQuantity = projectFamily == 'MADERA ACORDEON' ? 1 : 2;
    let condition =  selectedRailings.length < railingQuantity && this.railingTargets.length > 1
    if (selectedRailings === [] || condition) {
      selectedRailings = []
      alert('Eliga cenefas y rieles para continuar.')
      return false
    }else{
      selectedRailings = []
      Turbolinks.visit(url)
    }
  }
}