import { Controller } from "@hotwired/stimulus"

let w = null
let h = null

let space = null
let divisions = null
let doors = null
let dividers = null
let initialWidth = null
let initialHeight = null
let heightOffset = null
let accordion = null

export default class extends Controller {
  static targets = ['canvas','space', 'division', 'door', 'divider', 'accordion']

  connect(){
    let context = null
    let canvas = null
    space = this.spaceTarget
    canvas = this.canvasTarget
    dividers = this.dividerTargets
    divisions = this.divisionTargets
    accordion = this.accordionTarget
    doors = this.doorTargets
    initialWidth = Number(space.dataset.width)
    initialHeight = Number(space.dataset.height)
    w = reSizeWidth(canvas,Number(space.dataset.width)) 
    h = reSizeHeight(canvas,Number(space.dataset.height))
    canvas.width = w
    canvas.height = h
    heightOffset = Number(space.dataset.offset)
    let partialSpace = reSizeWidth(canvas,Number(space.dataset.partial))
    if (heightOffset > 0) { h = h - reSizeHeight(canvas,250) }
    let isAccordion = accordion.dataset.value.toLowerCase() === 'true'
    if (partialSpace > 0 && !isAccordion) { w = partialSpace }
    context = canvas.getContext("2d")
    context.lineWidth = 2
    if (isAccordion){
      let url = accordion.dataset.url
      drawFillings(canvas,context).then(()=>{
        drawAccordion(canvas,context,url)
      })
    } else {
      drawFillings(canvas,context).then(() => {
        drawDivisions(canvas,context)
        drawDoors(canvas,context)
        drawTypes(canvas,context)
        drawMeasures(canvas,context)
      })
    }
  }

  print(){
    window.print()
  }
}

const reSizeWidth = (canvas, value ) => {
  let canvasWidth = canvas.offsetWidth
  let proportion = initialWidth/canvasWidth
  value /= proportion
  return value
}

const reSizeHeight = (canvas, value ) => {
  let canvasHeight = canvas.offsetHeight
  let proportion = initialHeight/canvasHeight
  value /= proportion
  return value
}

const drawDivisions = (canvas,context) => {
  dividers.forEach((divider) => {
    let height = Number(divider.dataset.height)
    let newSpace = (initialHeight - ( heightOffset > 0 ? 250 : 0) ) - height
    drawLine(context, 0, reSizeHeight(canvas,newSpace), w, reSizeHeight(canvas,newSpace))
  })
}

const drawDoors = (canvas,context) => {
  let coveredSpace = 0
  doors.forEach((door) => {
    let width = reSizeWidth(canvas,Number(door.dataset.width))
    coveredSpace += width
    drawLine(context, coveredSpace, 0, coveredSpace, h)
    // if (w != coveredSpace) {  drawLine(coveredSpace, 0, coveredSpace, h) }
  })
}

const drawTypes = async (canvas, context) => {
  let totalWidth = 0
  await asyncForEach(doors, async ( door ) =>{
    let width = reSizeWidth(canvas,Number(door.dataset.width))
    let url = door.dataset.image
    let typeImage = new Image();
    console.log('drawTypes: ', context)
    typeImage.src = url
    await new Promise( (resolve) => { 
      typeImage.onload = () => { 
        let position = totalWidth + width/2 - reSizeWidth(canvas,typeImage.width*0.35)/2
        context.drawImage(typeImage, position, h/2 - reSizeHeight(canvas,typeImage.height*0.25)/2 , reSizeWidth(canvas,typeImage.width*0.35), reSizeHeight(canvas,typeImage.height*0.25))
        totalWidth += width
        resolve()
      }
    })
  })
}

const drawMeasures = (canvas, context) => {
  context.textAlign = 'center'
  context.font = `25px monospace`
  let totalWidth = 0
  console.log('Draw Measures: ', context)
  doors.forEach((door) => {
    let width = Number(door.dataset.width)
    let position = totalWidth + width/2
    let isAccordion = accordion.dataset.value.toLowerCase() === 'true'
    let height = h
    height = isAccordion ? height*1.075 : height*0.975
    context.fillText(width, reSizeWidth(canvas,position) , height)
    totalWidth += width
  })
  dividers.forEach((divider) => {
    let height = +divider.dataset.height
    let position = totalWidth*0.035
    if ( height > 0 ){
      context.fillText(height, reSizeWidth(canvas,position) , h - reSizeHeight(canvas,height)*1.1)
    }
  })
  let offset = Number(space.dataset.offset)
  drawOffset(canvas,context, offset)
}

const drawOffset = (canvas, context, offset ) => {
  if ( offset > 0 ){ 
    context.fillText( offset , w*0.05 , h*0.985 + (offset < 150 ? reSizeHeight(canvas,150) : reSizeHeight(canvas,offset)))
  }
}

const drawFillings = async (canvas, context) => {
  divisions = divisions.reverse()
  let lastHeight = 0
  await asyncForEach(divisions, async (division) =>{
    let url = division.dataset.fill
    console.log('drawFillings: ', context)
    let fillImage = new Image()
    fillImage.src = url
    await new Promise( (resolve) => { 
      fillImage.onload = () => { 
        let isAccordion = accordion.dataset.value.toLowerCase() === 'true'
        let height = isAccordion ?  2700 : +division.dataset.height
        context.drawImage(fillImage, 0, lastHeight,  w, reSizeHeight(canvas,height))
        lastHeight += reSizeHeight(canvas,Number(division.dataset.height))
        resolve()
      }
    })
  })
}

const drawAccordion = async (canvas, context, url) => {
  let accordionImage = new Image()
  accordionImage.src = url
  console.log('drawAccordion: ', context)
  await new Promise( (resolve) => { 
    accordionImage.onload = () => {
      context.drawImage(accordionImage, 0, 0 - reSizeHeight(canvas,initialHeight)*0.01,  w,reSizeHeight(canvas,initialHeight)*1.02)
      resolve()
     }
  })
  drawMeasures(context)
}

const drawLine = (context, x1, y1, x2, y2) => {
  console.log('DrawLine: ',context)
  context.moveTo(x1, y1);
  context.lineTo(x2, y2);
  context.stroke();
}

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}