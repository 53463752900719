import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "progressbar" ]

  removeNoticeContainer() {
    this.element.classList.add('dismissed')
  }

  connect() {
  }
}
