
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "accordion" ]

  wood(event) {
    event.target.classList.add('selected')
    this.accordionTarget.classList.remove('disabled')
    return false
  }

  next(event){
    let buttons = document.querySelectorAll('.btn')
    buttons.forEach((btn) => {
      btn.classList.add('disabled')
    })
  }
}