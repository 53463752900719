import { Controller } from "@hotwired/stimulus"
import Turbolinks from "turbolinks"

export default class extends Controller {
  static targets = ['auxiliarDoor',
                    'horizontalDivisionButton',
                    'horizontalDivisionDisclaimer', 'horizontalDivisionField', 'horizontalDivisionSection',
                    'verticalDivisionButton']
  static values = {
    doorMaterial: String,
    verticalDivisionCondition: Boolean,
    hasDivision: Boolean, 
  }

  connect() {
    this.horizontalDivisionButtons = this.hasHorizontalDivisionButtonTarget ? this.horizontalDivisionButtonTargets : null
    this.horizontalDivisionDisclaimer = this.hasHorizontalDivisionDisclaimerTarget ? this.horizontalDivisionDisclaimerTarget : null
    this.horizontalDivisionField = this.hasHorizontalDivisionFieldTarget ? this.horizontalDivisionFieldTarget : null
    this.horizontalDivisionSection = this.hasHorizontalDivisionSectionTarget ? this.horizontalDivisionSectionTarget : null
    this.verticalDivisionButtons = this.hasVerticalDivisionButtonTarget ? this.verticalDivisionButtonTargets : null
    this.auxiliarDoor = this.hasAuxiliarDoorTarget ? this.auxiliarDoorTarget : null
    if(window.location.href.includes("accordion")) {
      this.selectBackgroud("accordion_area")
    }
    else {
      if(window.location.href.includes("wood")) {
        this.selectBackgroud("wood_area")
      }
      else {
        this.selectBackgroud("aluminium_area")
      }
    }
  }

  selectBackgroud(option) {
    let drawArea = document.getElementById("preview_selection")
    drawArea.classList.remove('wood_area','aluminium_area','accordion_area')
    drawArea.classList.add(`${option}`)
  }

  initialize() {
    if (this.doorMaterialValue === 'wood') this.next()
  }

  selectHorizontalDivision(event){
    this.clickedHorizontalDivision = event.target
    this.horizontalDivisionQuantity = this.clickedHorizontalDivision.dataset.divisions
    this.horizontalDivisionButtons.forEach((horizontalDivision) => {
      horizontalDivision.classList.remove('btn-primary-simple')
      horizontalDivision.classList.add('btn-primary-outline')
    })
    this.clickedHorizontalDivision.classList.remove('btn-primary-outline')
    this.clickedHorizontalDivision.classList.add('btn-primary-simple')
    this.enableHorizontalDivisionOptions()
  }

  enableHorizontalDivisionOptions(){
    this.horizontalDivisionFieldTargets.forEach((horizontalDivisionField) => {
      if(!Array.from(horizontalDivisionField.classList).includes('d-none')) { horizontalDivisionField.remove() }
    })

    if(Number(this.horizontalDivisionQuantity) === 0){
      this.horizontalDivisionDisclaimer.classList.add('d-none')
    }
    else {
      Array.from({ length: this.horizontalDivisionQuantity }, (x, i) => {
        let newWidthField = this.horizontalDivisionField.cloneNode(true)
        newWidthField.classList.remove('d-none')
        newWidthField.children[0].firstChild.innerHTML = `Division ${i + 1}`
        this.horizontalDivisionSection.append(newWidthField)
      });
      this.horizontalDivisionDisclaimer.classList.remove('d-none')
    }
    this.calculateHorizontalDivisions()
  }

  calculateHorizontalDivisions = () => {
    if(this.horizontalDivisionQuantity < 3) { return }

    let horizontalDivisionSize = Math.trunc(Number(this.auxiliarDoor.dataset.heigth) / (Number(this.horizontalDivisionQuantity) + 1))
    let currentPosition = 0
    this.horizontalDivisionFieldTargets.forEach((horizontalDivisionField) => {
      if(!Array.from(horizontalDivisionField.classList).includes('d-none')) { 
        currentPosition += horizontalDivisionSize
        horizontalDivisionField.children[1].children[0].children[0].value = currentPosition
        horizontalDivisionField.classList.add('disabled')
      }
    })
  }

  calculateVerticalDivisions = () => {
    if(!this.verticalDivisionQuantity || this.verticalDivisionQuantity === 0) { return '0' }

    let verticalDivisionSize = Math.trunc(Number(this.auxiliarDoor.dataset.width) / (Number(this.verticalDivisionQuantity) + 1))
    let currentPosition = 0
    let verticalPositions = []
    Array.from({ length: this.verticalDivisionQuantity }, (x, i) => {
      currentPosition += verticalDivisionSize
      verticalPositions.push(currentPosition)
    });
    return verticalPositions.join(',')
  }

  horizontalDivisionsValidation = () => {
    let heights = this.horizontalDivisionFieldTargets.filter(field => !field.classList.contains('d-none')).map(field => field.children[1].children[0].children[0].value)
    let spaceHeight = Number(this.auxiliarDoor.dataset.heigth)

    const invalidValues = !heights.every(value => /^\d+$/.test(value) && Number.isInteger(parseInt(value)))
    let state = false
    let alertText = ''
  
    if(invalidValues){
      alertText = 'Ingresa valores de altura validos para continuar.'
    }
    else if(Math.max(...heights) >= spaceHeight){
      alertText = 'Tu division no puede ser igual o superar el alto de la puerta.'
    }
    else if(Math.min(...heights) <= 0){
      alertText = 'No puedes ingresar una medida inferior o igual a 0.'
    }
    else if(heights.length != [... new Set(heights)].length){
      alertText = 'No puedes ingresar medidas repetidas.'
    }
    else if(this.checkDivisionsHeight(heights)){
      alertText = 'La medida entre divisiones no puede ser menor a 10mm.'
    }
    else{
      state = true
      alertText = ''
    }

    if(alertText != '') { alert(alertText) }
    return state
  }

  checkDivisionsHeight = (heights) => {
    let divisionValidation = false
    if(heights.length == 1) { return divisionValidation }
  
    heights.forEach(height => {
      heights.forEach(comparationHeight => {
        let validation = Math.abs(height - comparationHeight)
        if(validation <= 10 && validation != 0) { divisionValidation = true }
      })
    })
    return divisionValidation
  }

  verticalDivisionsValidation = () => {
    if(this.verticalDivisionConditionValue && this.verticalDivisionQuantity == undefined){
      alert('Seleccione una opción en la sección de divisiones verticales')
      return false
    }
    else{
      return true
    }
  }

  selectVerticalDivision(event){
    this.clickedVerticalDivision = event.target
    this.verticalDivisionQuantity = this.clickedVerticalDivision.dataset.divisions

    this.verticalDivisionButtons.forEach((verticallDivision) => {
      verticallDivision.classList.remove('btn-primary-simple')
      verticallDivision.classList.add('btn-primary-outline')
    })

    this.clickedVerticalDivision.classList.remove('btn-primary-outline')
    this.clickedVerticalDivision.classList.add('btn-primary-simple')
  }

  next(){
    if(!this.hasDivisionValue || (this.verticalDivisionsValidation() && this.horizontalDivisionsValidation())){
      let horizontalDivisions = this.horizontalDivisionFieldTargets.filter(field => !field.classList.contains('d-none')).map(field => Number(field.children[1].children[0].children[0].value)).join(',')
      let verticalDivisions = this.calculateVerticalDivisions()
      Turbolinks.visit(this.buildURI(horizontalDivisions, verticalDivisions))
    }
  }

  buildURI(horizontalDivisions, verticalDivisions){
    let horizontalURI = horizontalDivisions === '' ? 0 : horizontalDivisions
    let verticalURI = verticalDivisions === '' ? 0 : verticalDivisions

    return `./${horizontalURI}/${verticalURI}/`
  }
}
