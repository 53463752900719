
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['width', 'height','offset','next']

  next(){
    let width = Number(this.widthTarget.value )
    let height = Number(this.heightTarget.value)
    let maxWidth = Number(this.widthTarget.max)
    let maxHeight = Number(this.heightTarget.max)
    let minWidth = Number(this.widthTarget.min)
    let minHeight = Number(this.heightTarget.min)
    let offset = 0
    let minOffset = 0
    let maxOffset = 0
    if ( this.hasOffsetTarget ) {
      offset = Number(this.offsetTarget.value)
      minOffset = Number(this.offsetTarget.min)
      maxOffset = Number(this.offsetTarget.max)
    }
    let url = `./${width}x${height},${offset}/#step-cover`
    let valid = validateFields(width, height, maxWidth, maxHeight, minWidth, minHeight, offset, minOffset, maxOffset)
    if (valid) { Turbolinks.visit(url) }
  }
}

let validateFields = (width, height, maxWidth, maxHeight, minWidth, minHeight, offset, minOffset, maxOffset) => {
  let hasNegativeValue = width < 0 || height < 0
  let inMaxWidthLimit = width > maxWidth
  let inMaxHeightLimit = height > maxHeight
  let inMinWidthLimit = width < minWidth
  let inMinHeigthLimit = height < minHeight
  let inMinOffsetLimit = offset < minOffset
  let inMaxOffsetLimit = offset > maxOffset

  let isNumeric = /^\d+$/.test(width) || /^\d+$/.test(height)
  if (!isNumeric || hasNegativeValue ) {
    alert('Ingresa ancho y alto validos para continuar.')
    return false
  } 
  if(inMaxWidthLimit){
    alert('Tu vano supera el ancho máximo de las puertas.')
    return false
  }
  if(inMaxHeightLimit){
    alert('Tu vano supera el alto máximo de las puertas.')
    return false
  }
  if(inMinWidthLimit){
    alert('Tu vano tiene un ancho inferior al minimo')
    return false
  }
  if(inMinHeigthLimit){
    alert('Tu vano tiene un alto inferior al minimo')
    return false
  }
  if(inMinOffsetLimit){
    alert(`Despeje debe de tener un mínimo de ${minOffset} milímetros`)
    return false
  }
  if(inMaxOffsetLimit){
    alert(`Tu despeje supera el maximo de ${maxOffset} milímetros`)
    return false
  }
  return true
}