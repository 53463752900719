require('aframe')

export const wall = AFRAME.registerComponent('wall', {
  init: function () {
    // Code for initializing a wall here
  },
  // Other lifecycle methods or properties here
});

export const door = AFRAME.registerComponent('door', {
  init: function() {}
})

export const gap = AFRAME.registerComponent('gap', {
  init: ()=>{}
})

export default {
  components: [ wall, door, gap]
}