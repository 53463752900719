
import { Controller } from "@hotwired/stimulus"
let divisions = 0
export default class extends Controller {
  static targets = ['divisionButton', 'divisions', 'width', 'widthMilimiters']

  // Division

  // Controll the buttons and values for the vertical dividers
  // Enable the buttons and hide/show the input for distance
  // @params event [Object] The element clicked
  // @returns nil
  division(event) {
    divisions = event.target.dataset.divisions
    let selectedDivisions = event.target.innerHTML
    let spaceWidth = Number(this.widthTargets[0].dataset.doorWidth)
    this.divisionButtonTargets.forEach((button) => button == event.target ? button.classList.add('selected') : button.classList.remove('selected'))
    let dividersDistance = calculateDividerDistance(selectedDivisions, spaceWidth)
    let url = `./${dividersDistance}/#step-fill`
    Turbolinks.visit(url)
    // this.divisionsTarget.classList.remove('d-none')
    
    // this.widthTargets.forEach(field => parseInt(field.dataset.index) <= selectedDivisions ? field.classList.remove('d-none') : field.classList.add('d-none'))
  }

  // Next

  // Controll the next button
  // Validate if the requiered fields where completed 
  // before enable the next button
  // @returns nil
  next(){
    const widths = this.widthTargets.filter(field => !field.classList.contains('d-none')).map(field => field.firstChild.value)
    console.log('Widths: ', widths)
    let spaceWidth = Number(this.widthTargets[0].dataset.doorWidth)
    let valid = validateFields(widths, spaceWidth)
    if (valid) {
      let url = `./${widths.join(',')}/#step-fill`
      Turbolinks.visit(url)
    }
  }
}

// Validate Fields

// @params Array WidthFields, array with the distance of each divider
// @params Integer SpaceWidth, Width of the door
// Validate if the distance of the dividers
// @returns [Boolean] valid or invalid
let validateFields = (widthFields, spaceWidth) => {
  const widths = widthFields
  const invalidValues = !widths.every(value => /^\d+$/.test(value) && Number.isInteger(parseInt(value)))
  let state = false
  let alertText = ''

  if(invalidValues){
    alertText = 'Ingresa valores de ancho validos para continuar.'
  }
  else if(Math.max(...widths) >= spaceWidth){
    alertText = 'Tu division no puede superar el ancho de la puerta.'
  }
  else if(Math.min(...widths) <= 0){
    alertText = 'No puedes ingresar una medida inferior a 0.'
  }
  else if(widths.length != [... new Set(widths)].length){
    alertText = 'No puedes ingresar medidas repetidas.'
  }
  else if(checkDivisionsWidth(widths)){
    alertText = 'La medida entre divisiones no puede ser menor a 30mm.'
  }
  else{
    state = true
    alertText = ''
  }
  if(!state){
    alert(alertText)
  }
  return state
}

// Calculate Divider Distance
// @params dividerQuantity [Number] Number of divisions
// @params doorWidth [Number] The height of the door
//
// This method calculates the distance between dividers in a door based on the number of dividers and the height of the door.
//
// @returns [String]  A string containing the distances between the dividers, separated by commas.
let calculateDividerDistance = (dividerQuantity, doorWidth) => {
  let sections = parseInt(dividerQuantity) +1
  let dividerSize = Math.trunc(parseInt(doorWidth) / parseInt(sections))
  let currentPosition = 0
  let positions = []
  for(var i = 0; i < dividerQuantity; i++){
    currentPosition += dividerSize
    positions.push(currentPosition)
  }
  return positions.join(',')
}

// Check Divisions Width

// @params widths [Array] with the distance of each division 
// Compare each division validating the minimum distance
// between division is 30mm
// Validates that every division is a different value
// @returns [Boolean] valid or invalid distances
let checkDivisionsWidth = (widths) => {
  let divisionValidation = false
  if(widths.length == 1) { return divisionValidation }

  widths.forEach(width => {
    widths.forEach(comparationHeight => {
      let validation = Math.abs(width - comparationHeight)
      if(validation <= 30 && validation != 0) { divisionValidation = true }
    })
  })
  return divisionValidation
}
