import { Controller } from "@hotwired/stimulus"

let doorQuantity = 0
export default class extends Controller {
  static targets = ["width"]

  next(){
    let index = 0
    let maxTotal = 0
    let widths = []
    let valid = false
    let cover = ''
    for (let width of this.widthTargets) {
      cover = width.dataset.cover
      maxTotal = width.dataset.maxWidth
      let maxSingle = width.max
      let minSingle = width.min
      widths.push(width.value)
      valid = validateFields(width.value, widths, maxTotal, maxSingle, minSingle)
      if (!valid) {break}
      index = index+1
    }

    let totalWidth = widths.reduce((a,b)=>Number(a)+Number(b))
    let coverValid = cover == 'full' ? totalWidth == maxTotal : true
    if (valid && !coverValid){
      alert(`Ancho total de las puertas (${totalWidth}) no cubren el vano completo (${maxTotal}), Cambia las medidas o agrega mas puertas`)
      return false
    }
    let widthsString = widths.join()
    let url = `./${widthsString}/#step-type`
    if (valid) {Turbolinks.visit(url)}
  }
}
let validateFields = (width, widths, maxTotal, maxSingle, minSingle) => {
  let totalWidth = widths.reduce((a,b)=>Number(a)+Number(b))
  let hasNegativeValue = width < 0
  let isNumeric = /^\d+$/.test(width)
  let isInRange = Number(totalWidth) <= Number(maxTotal)
  let singleMaxSize = Number(width) <= Number(maxSingle)
  let singleMinSize = Number(width) >= Number(minSingle)
  if (!isNumeric || hasNegativeValue ) {
    alert('Ingresa valores de ancho validos para continuar.')
    return false
  }
  if (!isInRange) {
    alert(`Ancho total de las puertas (${totalWidth}) superan ancho maximo del vano (${maxTotal})`)
    return false
  }
  if (!singleMaxSize) {
    alert(`Ancho de la puerta (${width}) supera el maximo (${maxSingle}), cambie las medidas de la puerta`)
    return false
  }
  if (!singleMinSize) {
    alert(`Ancho de la puerta (${width}) es inferior al minimo (${minSingle}), cambie las medidas de la puerta`)
    return false
  }
  
  
  return true
}