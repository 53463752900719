import { Controller } from "@hotwired/stimulus"
let context = null
let w = null
let h = null
let canvas = null
let space = null
let divisions = null
let verticalDividers = null
let doors = null
let dividers = null
let initialWidth = null
let initialHeight = null
let resizeInitialHeight = null
let spaceWidth = null
let heightOffset = 0
let accordion = null
let canvasHeight = null
let canvasWidth = null
let canvasVertical = null
let canvasHorizontal = null
let canvasDoorSize = null
let supportType = null
export default class extends Controller {
  static targets = ['canvas','canvasVertical', 'canvasHorizontal', 'canvasWidth','canvasHeight', 'canvasDoorSize','space', 'division', 'vertical', 'door', 'horizontal', 'accordion']
  connect() {
    space = this.spaceTarget
    canvas = this.canvasTarget
    canvasWidth = this.canvasWidthTarget
    canvasHeight = this.canvasHeightTarget
    divisions = this.divisionTargets
    dividers = this.horizontalTargets
    verticalDividers = this.verticalTargets
    accordion = this.accordionTarget
    doors = this.doorTargets
    canvasVertical = this.canvasVerticalTarget
    canvasHorizontal = this.canvasHorizontalTarget
    canvasDoorSize = this.canvasDoorSizeTarget
    initialWidth = Number(space.dataset.width)
    initialHeight = Number(space.dataset.height)
    supportType = space.dataset.support
    w = reSizeWidth(initialWidth) 
    h = reSizeHeight(initialHeight)
    spaceWidth = w
    resizeInitialHeight = reSizeHeight(initialHeight)
    canvas.width = w
    canvas.height = h
    canvasWidth.width = w;
    canvasWidth.height = reSizeHeightCanvas(initialHeight, canvasWidth);
    canvasHeight.height = h;
    canvasHeight.width = reSizeWidthCanvas(initialWidth, canvasHeight);
    canvasVertical.width = w;
    canvasVertical.height= reSizeWidthCanvas(initialHeight, canvasWidth);
    canvasHorizontal.height = h;
    canvasHorizontal.width = reSizeWidthCanvas(initialWidth, canvasHeight);
    canvasDoorSize.width = w
    canvasDoorSize.height = reSizeHeightCanvas(initialHeight, canvasWidth);
    heightOffset = Number(space.dataset.offset)
    let partialSpace = reSizeWidth(Number(space.dataset.partial))
    let isAccordion = accordion.dataset.value.toLowerCase() === 'true'
    if (isAccordion) { h = h - reSizeHeight(250) }
    if (partialSpace > 0 && !isAccordion) { w = partialSpace }
    context = canvas.getContext("2d")
    context.lineWidth = 2
    if (isAccordion){
      let url = accordion.dataset.url
      drawFillings().then(()=>{
        drawAccordion(url)
        drawMeasuresLines();
      })
    } else {
      drawFillings().then(() => {
        drawMeasures()
        drawMeasuresLines();
        drawDivisions()
        drawDoors()
        drawTypes()
      })
    }
  }
  print(){
    window.print()
  }
}

const reSizeWidth = ( value ) => {
  let canvasWidth = canvas.offsetWidth 
  let proportion = initialWidth/canvasWidth
  value /= proportion
  return value
}

const reSizeHeight = ( value ) => {
  let canvasHeight = canvas.offsetHeight
  let proportion = initialHeight/canvasHeight
  value /= proportion
  return value
}

const reSizeWidthCanvas = ( value, container ) => {
  let canvasWidth = container.offsetWidth 
  let proportion = initialWidth/canvasWidth
  value /= proportion
  return value
}

const reSizeHeightCanvas = ( value, container ) => {
  let canvasHeight = container.offsetHeight
  let proportion = initialHeight/canvasHeight
  value /= proportion
  return value
}

// Draw Divisions
// 
// Check if the quote has vertical or horizontal dividers
// then draw each line in the preview canvas
const drawDivisions = () => {
  let horizontalSectionSize = 0
  let horizontalSectionDistance = 0
  dividers.forEach((divider, index) => {
    let height = Number(divider.dataset.height)
    let newSpace = (initialHeight - ( heightOffset > 0 ? 250 : 0) ) - height
    drawLine( 0, reSizeHeight(newSpace), w, reSizeHeight(newSpace))
    drawHorizontalDividerArrow(height, newSpace)
  })
  dividers.forEach((divider, index) => {

    if(index + 1 === dividers.length ){
      horizontalSectionSize = Number(divider.dataset.height)
      horizontalSectionDistance = initialHeight - horizontalSectionSize/2
    }else{
      horizontalSectionSize = (Number(divider.dataset.height) - Number(dividers[index + 1].dataset.height))
      horizontalSectionDistance = initialHeight - ((horizontalSectionSize/2) + Number(dividers[index + 1].dataset.height))
    }
  })
  let doorWidth = 0
  let verticalSectionSize = 0
  let verticalSectionDistance = 0
  let width = 0
  doors.forEach((door) => {
    let sectionPosition = doorWidth
    verticalSectionSize = Math.trunc(parseInt(door.dataset.width) / (parseInt(verticalDividers.length) + 1)) - 28
    verticalDividers.forEach((divider, index) => {
      width = Number(divider.dataset.width) + doorWidth
      if (sectionPosition === 0) {
        verticalSectionDistance = width/2
        sectionPosition = verticalSectionDistance
      } else {
        sectionPosition = width - verticalSectionDistance
      }
      drawLine( reSizeWidth(width), 0, reSizeWidth(width), h)
      drawVerticalDividerArrow(divider.dataset.width, width, verticalSectionSize)
    })
    doorWidth += +door.dataset.width
    sectionPosition = doorWidth - verticalSectionDistance
  })
}

// Draw Vertical Divider Arrow
// 
// Draw a line above each vertical divider with the distance of each divider
const drawVerticalDividerArrow = (width, distance, sectionSize) => {
  if(width == 0) return
  drawLineCanvas(canvasVertical, reSizeWidth(distance), 15, reSizeWidth(distance), 55, 2)
  drawMeasuresDivider(canvasVertical, reSizeWidth(distance), 13, width, 18)
  // drawMeasuresDivider(canvasVertical, reSizeWidth(distance/2), 13, sectionSize, 18)
}

// Draw Horizontal Divider Arrow
// 
// Draw a line to the right of each horizontal divider with the distance of each divider
const drawHorizontalDividerArrow = (height, distance) => {
  if(height == initialHeight || height == 0) return
  drawLineCanvas(canvasHorizontal, 25, reSizeHeightCanvas(distance, canvasHorizontal), 70, reSizeHeightCanvas(distance, canvasHorizontal), 3)
  drawMeasuresDivider(canvasHorizontal, 35, reSizeHeightCanvas(distance-30, canvasHorizontal), height, 18)
}

// Draw Doors
// 
// Draw the lines of each door in the canvas with the door size of the quote
const drawDoors = () => {
  let coveredSpace = 0
  doors.forEach((door) => {
    let width = reSizeWidth(Number(door.dataset.width))
    coveredSpace += width
    drawLine(coveredSpace, 0, coveredSpace, h)
    // if (w != coveredSpace) {  drawLine(coveredSpace, 0, coveredSpace, h) }
  })
}

const drawTypes = async () => {
  let totalWidth = 0
  await asyncForEach(doors, async ( door ) =>{
    let width = reSizeWidth(Number(door.dataset.width))
    let url = door.dataset.image
    let typeImage = new Image();
    typeImage.src = url
    await new Promise( (resolve) => { 
      typeImage.onload = () => { 
        let position = totalWidth + width/2 - reSizeWidth(typeImage.width*0.35)/2
        context.drawImage(typeImage, position, h/2.3 , reSizeWidth(typeImage.width*0.35), reSizeHeight(typeImage.height*0.25))
        totalWidth += width
        resolve()
      }
    })
  })
}

const drawMeasures = () => {
  context.textAlign = 'center'
  context.font = `14px monospace`
  let currentPosition = 0
  doors.forEach((door) => {
    let isAccordion = accordion.dataset.value.toLowerCase() === 'true'
    let width = +door.dataset.width
    let position = currentPosition + width/2
    if ( isAccordion && doors.length > 1 ) { 
      position = currentPosition + +initialWidth/4
      let height = h
      height = isAccordion ? height*1.075 : height*0.975
      context.fillText(width, reSizeWidth(position) ,height)
      currentPosition += (isAccordion ? +initialWidth/2 : width)
    }else{
      // Draw  Width lines with measures in the midle
      // position = currentPosition + width/2
      position = reSizeWidth(position)
      let startArrow = reSizeWidth(currentPosition)
      let endArrow = reSizeWidth(currentPosition+width)
      drawLineCanvas(canvasDoorSize, startArrow, 10, startArrow, 30, 3)
      drawLineCanvas(canvasDoorSize, startArrow, 20, (position)-40, 20, 3)
      drawLineCanvas(canvasDoorSize, (position)+40, 20, endArrow, 20, 3)
      drawLineCanvas(canvasDoorSize, endArrow, 10, endArrow, 30, 3)
      drawMeasuresCanvas(canvasDoorSize, (position), 27, width)
      currentPosition += width
    }
  })
  let offset = Number(space.dataset.offset) || 100
  drawOffset(offset)
}

const drawOffset = ( offset ) => {
  let isAccordion = accordion.dataset.value.toLowerCase() === 'true'
  if ( offset > 0 && isAccordion && supportType == 'ceiling' ){ 
    context.fillText( offset , w*0.05 , h*0.985 + (offset < 150 ? reSizeHeight(200) : reSizeHeight(offset)))
    drawLine(15, reSizeHeight(initialHeight-offset-100), 25, reSizeHeight(initialHeight-offset-100) )
    drawLine(20, reSizeHeight(initialHeight-offset-100), 20, reSizeHeight(initialHeight-5) )
    drawLine(15, reSizeHeight(initialHeight-5), 25, reSizeHeight(initialHeight-5) )
  }
}

// Draw Fillings
// 
// Fill each door segment with the corresponding filling desing from the quote
// Fill each divider too
const drawFillings = async () => {
  let lastHeight = 0
  let originalLastHeight = 0
  let index = 0
  await asyncForEach(divisions, async (division) =>{
    let url = division.dataset.fill
    let fillImage = new Image()
    fillImage.src = url
    await new Promise( (resolve) => { 
      fillImage.onload = () => { 
        let isAccordion = accordion.dataset.value.toLowerCase() === 'true'
        let nextHeight = divisions[index+1] ? divisions[index+1].dataset.height : 0
        let height = isAccordion ?  2700 : +division.dataset.height - nextHeight
        originalLastHeight += height
        context.drawImage(fillImage, 0, lastHeight,  w, height)
        lastHeight += reSizeHeight(height)
        
        resolve()
      }
    })
    index += 1
  })
}

const drawAccordion = async (url) => {
  let accordionImage = new Image()
  accordionImage.src = url
  await new Promise( (resolve) => { 
    accordionImage.onload = () => {
      context.drawImage(accordionImage, 0, 0 - reSizeHeight(initialHeight)*0.01,  w,reSizeHeight(initialHeight)*1.02)
      resolve()
    }
  })
  drawMeasures()
}

const drawLine = (x1, y1, x2, y2) => {
  context.moveTo(x1, y1);
  context.lineTo(x2, y2);
  context.stroke();
}

const drawLineCanvas = (container, x1, y1, x2, y2, strokeSize) => {
  let drawContext = container.getContext('2d')
  drawContext.lineWidth = strokeSize
  drawContext.moveTo(x1, y1);
  drawContext.lineTo(x2, y2);
  drawContext.stroke();
}

// Draw Measures Divider
// @params container [Canvas] Canvas where to render
// @params xAxis [Integer] X coordinate in the canvas
// @params yAxis [Integer] Y Coordinate in the canvas
// @params text [String] Text to draw in the canvas
// @params fontSize [Integer] Size of the font in the canvas
// 
// Draw text in the canvas with configurable font size
const drawMeasuresDivider = (container, xAxis, yAxis, text, fontSize) => {
  let drawContext = container.getContext('2d')
  drawContext.textAlign = 'center'
  drawContext.font = `${fontSize}px monospace`
  drawContext.fillText(text, xAxis, yAxis)
}

const drawMeasuresCanvas = (container, xAxis, yAxis, text) => {
  let drawContext = container.getContext('2d')
  drawContext.textAlign = 'center'
  drawContext.font = `24px monospace`
  drawContext.fillText(text, xAxis, yAxis)
}

const drawMeasuresLines = () => {
  // Draw  Width lines with measures in the midle
  drawLineCanvas(canvasWidth, 0, 10, 0, 30, 3)
  drawLineCanvas(canvasWidth, 0, 20, (spaceWidth/2)-40, 20, 3)
  drawLineCanvas(canvasWidth, spaceWidth, 10, spaceWidth, 30, 3)
  drawLineCanvas(canvasWidth, (spaceWidth/2)+40, 20, spaceWidth, 20, 3)
  drawMeasuresCanvas(canvasWidth, (spaceWidth/2), 27, initialWidth)

  // Draw  Hegiht lines with measures in the midle
  drawLineCanvas(canvasHeight, 10, 0, 30, 0, 3)
  drawLineCanvas(canvasHeight, 20, 0, 20, (resizeInitialHeight/2)-20, 3)
  drawLineCanvas(canvasHeight, 20, (resizeInitialHeight/2)+20, 20, resizeInitialHeight, 3)
  drawLineCanvas(canvasHeight, 10, resizeInitialHeight, 30, resizeInitialHeight, 3)
  drawMeasuresCanvas(canvasHeight, 30, (resizeInitialHeight/2)+5, initialHeight)
}

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}