import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  // On connect, in Document view, hides the cart partial from navbar.
  connect(){
    let cartContainer = document.getElementById('cartContainer')
    cartContainer.classList.add('d-none')
  }
}
