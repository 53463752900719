
import { Controller } from "@hotwired/stimulus"
let divisions = 0
export default class extends Controller {
  static targets = ['divisionButton', 'divisions', 'height', 'doorHeight']

  // Division
  // When a division quantity is selected, add the select class to that button and enables
  // the respective field to input the height of the division.
  // @param event [Event] the division quantity button clicked.
  // @return [void] Sets various class to the buttons and inputs depending on the selected division quantity.
  division(event) {
    divisions = event.target.dataset.divisions
    let selectedDivisions = event.target.innerHTML
    let spaceHeight = this.doorHeightTarget.value
    this.divisionButtonTargets.forEach((button) => button == event.target ? button.classList.add('selected') : button.classList.remove('selected'))
    if(selectedDivisions < 3){
      this.divisionsTarget.classList.remove('d-none')
      this.heightTargets.forEach(field => parseInt(field.dataset.index) <= selectedDivisions ? field.classList.remove('d-none') : field.classList.add('d-none'))
    }else{
      this.divisionsTarget.classList.add('d-none')
      let dividersDistance = calculateDividerDistance(selectedDivisions, spaceHeight)
      let url = `./${dividersDistance}/#step-vertical`
      Turbolinks.visit(url)
    }
    
  }

  // Next
  // When the next button is clicked, it checks if the division quantity is
  // selected and if the height of each division is filled right.
  // @returns [void] If the division heights are filled right, it redirects to the next step.
  next(){
    const heights = this.heightTargets.filter(field => !field.classList.contains('d-none')).map(field => field.firstChild.value)
    let spaceHeight = this.doorHeightTarget.value
    let valid = validateFields(heights, spaceHeight)
    if (valid) {
      let url = `./${heights.join(',')}/#step-vertical`
      Turbolinks.visit(url)
    }
  }
}

// Validate Fields
// Validates the division heights.
// If there is a problem with the division heights, it shows an error message.
// @param heights [Array] the heights of the divisions.
// @param spaceHeight [Integer] the height of the space.
// @returns [Boolean] true if the division heights are valid, false otherwise.
let validateFields = (heights, spaceHeight) => {
  const invalidValues = !heights.every(value => /^\d+$/.test(value) && Number.isInteger(parseInt(value)))
  let state = false
  let alertText = ''

  if(invalidValues){
    alertText = 'Ingresa valores de altura validos para continuar.'
  }
  else if(Math.max(...heights) >= spaceHeight){
    alertText = 'Tu division no puede ser igual o superar el alto de la puerta.'
  }
  else if(Math.min(...heights) <= 0){
    alertText = 'No puedes ingresar una medida inferior o igual a 0.'
  }
  else if(heights.length != [... new Set(heights)].length){
    alertText = 'No puedes ingresar medidas repetidas.'
  }
  else if(checkDivisionsHeight(heights)){
    alertText = 'La medida entre divisiones no puede ser menor a 10mm.'
  }
  else{
    state = true
    alertText = ''
  }
  if(alertText != '') { alert(alertText) }
  return state
}

// Calculate Divider Distance
// @params dividerQuantity [Number] Number of divisions
// @params doorHeight [Number] The height of the door
//
// This method calculates the distance between dividers in a door based on the number of dividers and the height of the door.
//
// @returns [String]  A string containing the distances between the dividers, separated by commas.
let calculateDividerDistance = (dividerQuantity, doorHeight) => {
  let sections = parseInt(dividerQuantity) +1
  let dividerSize = Math.trunc(parseInt(doorHeight) / parseInt(sections))
  let currentPosition = 0
  let positions = []
  for(var i = 0; i < dividerQuantity; i++){
    currentPosition += dividerSize
    positions.push(currentPosition)
  }
  return positions.join(',')
}

/**
 * Check Divisions Height
 *
 * @param heights [Array] An array containing the heights of the divisions in the door
 *
 * Validates if the heights of divisions in a door are within 10mm of each other.
 *
 * @returns {Boolean} true if the difference in height between any two divisions is less than or equal to 10mm, false otherwise
 */
let checkDivisionsHeight = (heights) => {
  let divisionValidation = false
  if(heights.length == 1) { return divisionValidation }

  heights.forEach(height => {
    heights.forEach(comparationHeight => {
      let validation = Math.abs(height - comparationHeight)
      if(validation <= 10 && validation != 0) { divisionValidation = true }
    })
  })
  return divisionValidation
}
