import { Controller } from "@hotwired/stimulus"
import { Tooltip } from "bootstrap";

export default class extends Controller {
  connect(){
    this.disableButtonsByClass('a.btn')
    var tooltips = document.querySelectorAll('[data-toggle="tooltip"]');
    tooltips.forEach(function (tooltip) {
      new Tooltip(tooltip, {
        html: true,
      });
    });
  } 

  disableButtonsByClass(klass) {
    const buttons = document.querySelectorAll(klass)
    buttons.forEach((btn) => {
      btn.addEventListener('click', () => {
        btn.classList.add('disabled')
        setTimeout(() => {
          btn.classList.remove('disabled')
        }, 5000)
      })
    })
  }
}
