import { Controller } from "@hotwired/stimulus"
import previewEntities from "./preview_entities";


export default class extends Controller {
  static targets = ['scene']
  static values = {
    restrictions: Object
  }

  initialize() {
    this.restrictions = {}
    this.availableComponents = previewEntities.components
  }

  connect() { 
    //console.log('AFrame preview renderer online', this.renderContextTarget)
    //this.restrictions = {...this.restrictions, ...this.restrictionsValue}
    //console.log('Starting AFrame')
    //console.log(this.sceneTarget)
    //console.log(this.restrictions);
  }

  markSceneReady() {
    //console.log('Scene is ready for action')
    //console.log(this.availableComponents)
  }

  updateRestrictions(newRestrictions) {
    //this.restrictions = {...this.restrictions, ...newRestrictions}
    //this.renderAFrame(this.restrictions)
    //console.log(this.restrictions);
  }

  //renderAFrame(restrictions) {
  //  console.log(this.sceneTarget)
  //  // restrictions.nameOfRestriction
  //  // Pa que wea funciona
  //}
}
