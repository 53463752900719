
import { Controller } from "@hotwired/stimulus"
let doorType = []
let total_doors = ''
let typeVal = [false, false, false, false]
export default class extends Controller {
  static targets = ['type','fixed','mobile','typeButton1','typeButton2','typeButton3','typeButton4','quantity','door']

  connect(){
    console.log('Im in')
    let step = this.typeTarget.dataset.step
    if (step == 'type'){
      let type = this.typeTarget.dataset.value
      let quantity = +this.quantityTarget.dataset.quantity
      let doors = this.doorTargets
      total_doors = quantity
      if (type === 'accordion') {
        times( quantity ) ((i) => {
          let mobile = this.mobileTarget.cloneNode()
          setDoorType( i+1, 'mobile' )
          typeVal[i] = true
          doors[i].append(mobile)
          this.typeButton1Targets.forEach(( button ) =>{ 
            if (button.dataset.type == 'mobile') { button.classList.add('selected') }
          })
          this.typeButton2Targets.forEach(( button ) =>{ 
            if (button.dataset.type == 'mobile') { button.classList.add('selected') }
          })
        })
      }
    }
  }

  type(event){
    let doors = this.doorTargets
    let type = event.target
    let door = type.dataset.door
    total_doors = Number(this.quantityTarget.dataset.quantity)
    typeVal[Number(door)-1] = true
    let typeText = type.dataset.type
    if (doorType.length > 4){
      times (total_doors) (() => {
        doorType.push('')
      })
    }
    setDoorType(door,typeText)

    if (door == '1' ) { this.typeButton1Targets.forEach((button)=>{ button.classList.remove('selected')}) }
    if (door == '2' ) { this.typeButton2Targets.forEach((button)=>{ button.classList.remove('selected')}) }
    if (door == '3' ) { this.typeButton3Targets.forEach((button)=>{ button.classList.remove('selected')}) }
    if (door == '4' ) { this.typeButton4Targets.forEach((button)=>{ button.classList.remove('selected')}) }
    doors.forEach((d) => {
      if (d.dataset.number == door){ d.innerHTML = ''}
    })
    type.classList.add('selected')
  }
  next(){
    let url = `./${doorType.join()}/#step-dividers`
    let valid = validateTypes()
    if (!valid) {alert('Seleccione todos los campos')}
    if (valid) {Turbolinks.visit(url)}
  }
}

let setDoorType = (door, type) => {
  doorType[+door-1] = type
}

let validateTypes = () => {
  if (total_doors == 1) {return typeVal[0]}
  if (total_doors == 2) {return typeVal[0] && typeVal[1]}
  if (total_doors == 3) {return typeVal[0] && typeVal[1] && typeVal[2]}
  if (total_doors == 4) {return typeVal[0] && typeVal[1] && typeVal[2] && typeVal[3]}
  return false
}

const times = n => f => {
  let iter = i => {
    if (i === n) return
    f (i)
    iter (i + 1)
  }
  return iter (0)
}