
import { Controller } from "@hotwired/stimulus"

let selectedDivision = 1
let divisionFillings = []
let selectedElement = null
let totalDivisions = 0
export default class extends Controller {
  static targets = [ "fill" ]

  connect() {
    divisionFillings = []
    if(document.querySelector('.dividers-template:last-child')) {
      totalDivisions = Number(document.querySelector('.dividers-template:last-child').dataset.division)
      console.log(totalDivisions)
      times (totalDivisions) (() => {
        divisionFillings.push('')
      })
      selectedElement = this.fillTargets[0]
      selectedDivision = Number(selectedElement.dataset.division)
      selectedElement.classList.add('division-selected')
      if(window.location.href.includes("accordion")) {
        this.selectBackgroud("accordion_area")
      }
      else {
        if(window.location.href.includes("wood")) {
          this.selectBackgroud("wood_area")
        }
        else {
          this.selectBackgroud("aluminium_area")
        }
      }
    }
  }

  selectBackgroud(option) {
    if(document.querySelector('.dividers-template:last-child')) {
      let drawArea = document.getElementById("preview_selection")
      drawArea.classList.remove('wood_area','aluminium_area','accordion_area')
      drawArea.classList.add(`${option}`)
    }
  }

  // Fill
  // 
  // Paint the selected section with the selected design
  fill(event) {
    const items = document.querySelectorAll('.filling_images')
    console.log(items)
    items.forEach((item) => {
      item.classList.remove('filling-selected')
    })
    event.target.classList.add('filling-selected')
    if(document.querySelector('.dividers-template:last-child')) {
      if (selectedDivision != 0) {
        let filling = event.target
        let fillingName = filling.dataset.filling
        let fillingSku = filling.dataset.sku
        divisionFillings[selectedDivision-1] = fillingSku
        let hasExceedLimit = checkFillingLimit(divisionFillings)
        if (hasExceedLimit) {
          divisionFillings[selectedDivision-1] = ''
          alert('Solo puedes elegir como maximo 2 rellenos. Elige uno de los que ya hayas seleccionado.')
          return false
        }
        let division = this.fillTargets[selectedDivision-1]
        division.dataset.selected = 'selected'
        let imageUrl = filling.dataset.image
        selectedElement.style = `background: url(${imageUrl}); opacity: 0.68`
        division.innerHTML = ''
        let newDiv = document.createElement("div")
        newDiv.className = "text-center filling-name"
        let fillTitle = document.createTextNode(fillingName)
        newDiv.appendChild(fillTitle)  
        division.appendChild(newDiv)
      }
    } else {
      let filling = event.target
      let fillingSku = filling.dataset.sku
      divisionFillings[0] = fillingSku
      let hasExceedLimit = checkFillingLimit(divisionFillings)
      if (hasExceedLimit) {
        divisionFillings[0] = ''
        alert('Solo puedes elegir como maximo 2 rellenos. Elige uno de los que ya hayas seleccionado.')
        return false
      }
    }
  }

  // Select
  // 
  // Select a door section to choose a filling desing
  select(event) {
    this.fillTargets.forEach((fill)=>{
      fill.classList.remove('division-selected')
    })
    selectedElement = event.target.closest('div')
    selectedDivision = Number(selectedElement.dataset.division)
    selectedElement.classList.add('division-selected')
  }

  // Next
  // 
  // Validates if all fillings where selected before continue
  next(event){
    let fillings = this.fillTargets
    let totalDivisions = Number(event.target.dataset.total)
    let url = `./${divisionFillings.join()}/#step-railing`
    let valid = validateFillings(totalDivisions, fillings)
    if (valid) { Turbolinks.visit(url) }
  }

  // Clear
  // 
  // Clear selected fillings
  clear(){
    this.fillTargets.forEach((target) => {
      target.style = ``
      target.innerHTML = ``
    })
    divisionFillings = []
  }
}
let validateFillings = (total, fillings) => {
  if(document.querySelector('.dividers-template:last-child')) {
    let fillingsSelected = 0
    fillings.forEach((fill)=>{
      if ( fill.dataset.selected == 'selected' ){ fillingsSelected += 1 }
    })

    console.log(fillingsSelected)
    console.log(total)

    if ( fillingsSelected == total ){ return true }
    alert('Selecciona un relleno para todas tus divisiones.')
    return false
  } else {
    console.log(divisionFillings)
    if(divisionFillings.length === 1) return true;
    return false;
  }
}

// Check Filling Limit
// Check if array has more than 2 fillings values.
// @params array [Array] Array to check.
// @return [Boolean] True if array has more than 2 fillings values, false otherwise.
let checkFillingLimit = (array) => {
  if ( !checkDuplicateValues(array) ){ return true }
  return false
}

// Check Duplicate Values
// Check if array has duplicate values.
// @params array [Array] Array to check.
// @return [Boolean] True if array has duplicate values, false otherwise.
let checkDuplicateValues = (array) => {
  let selectedValues = array.filter(element => element != '')
  return [... new Set(selectedValues)].length <= 2
}

let checkArrayValues = (array) => {
  let completed = true
  array.forEach(value => {
    if (value == ''){completed = false}
  })
  return completed
}

const times = x => f => {
  if (x > 0) {
    f()
    times (x - 1) (f)
  }
}
