
import { Controller } from "@hotwired/stimulus"
let selectedRailings = []
export default class extends Controller {
  static targets = [ "railing", "railings", "collapse", "productList", 'arrow', 'option' ]

  connect () {
    this.installationOption = null
    this.railingTargets.forEach((railing) => {
      let railingSelected = this.railingsTarget.dataset.railings.split(',').includes(railing.dataset.sku)
      railing.checked = railingSelected
    })
    if(window.location.href.includes("accordion")) {
      this.selectBackgroud("accordion_area")
    }
    else {
      if(window.location.href.includes("wood")) {
        this.selectBackgroud("wood_area")
      }
      else {
        this.selectBackgroud("aluminium_area")
      }
    }
  }

  selectBackgroud(option) {
    let drawArea = document.getElementById("preview_selection")
    drawArea.classList.remove('wood_area','aluminium_area','accordion_area')
    drawArea.classList.add(`${option}`)
  }

  select({ target }) {
    let railingSku = target.dataset.sku
    let projectFamily = target.dataset.family
    let railingQuantity = projectFamily == 'MADERA ACORDEON' ? 1 : 2;
    if ( target.checked ){
      selectedRailings.push(railingSku)
    } else {
      selectedRailings = selectedRailings.filter( r => r !== railingSku)
    }

    this.railingTargets.forEach((railing) => {
      if (selectedRailings.length >= railingQuantity ) {
        let selected = selectedRailings.includes(railing.dataset.sku)
        if (!selected ) {railing.parentNode.classList.add('disabled')}
      } else {
        railing.parentNode.classList.remove('disabled')
      }
    })
  }

  collapse(event){
    this.collapseTargets.forEach((element, index) => {
      if(element.dataset.id != event.target.dataset.id){
        element.classList.remove('show-collapse')
        element.previousElementSibling.lastElementChild.classList.remove('rotate-img')
        return
      }
      element.classList.toggle("show-collapse")
      this.arrowTargets.forEach((a) => {
        if ( a.dataset.id == event.target.dataset.id) { 
          a.classList.toggle('rotate-img')
        }
      })
    })
  }
  
  add(event){
    let element = document.querySelector(`#${event.target.dataset.id}`)
    let ul = document.querySelector('.accesories-preview')
    if(!element) {return}
    if(element.value.replace(/[^\d]+/,'').length <= 0) {return}

    let  li = document.createElement("li");
    li.appendChild(document.createTextNode(`${element.dataset.name} x ${element.value.replace(/[^\d]+/,'')}`));
    li.dataset.sku = element.dataset.sku
    li.dataset.quantity = element.value
    li.dataset.action = 'click->step-accesories#delete'
    ul.appendChild(li);
  }

  validateQuantity({ target }){
    target.value= target.value.replace(/[^\d]+/,'')
  }

  delete(event){
    event.target.parentNode.removeChild(event.target)
  }

  // selectInstallation({ params: { option }}) {
  //   this.optionTargets.forEach(item => {
  //     item.classList.toggle('selected', item.dataset.railingOptionParam === option)
  //   })
  //   this.installationOption = option;
  // }

  selectInstallation({ target }) {
    console.log(target.value)
    var selected = target.value;
    let enVersion = ''
    if(selected === 'Si') {
      enVersion = 'yes'
    } else if(selected === 'No') {
      enVersion = 'no'
    }
    this.installationOption = enVersion;
  }

  next({ target }) {
    let url = `./${selectedRailings.join(',')}/`
    let projectFamily = target.dataset.family
    let railingQuantity = projectFamily == 'MADERA ACORDEON' ? 1 : 2;
    let condition =  selectedRailings.length < railingQuantity && this.railingTargets.length > 1
    if (selectedRailings === [] || condition) {
      selectedRailings = []
      alert('Elija cenefas y rieles para continuar.')
      return false
    }
    if (!this.installationOption) {
      alert('Elija una opción de instalación')
      return false
    }
    selectedRailings = []

    let ul = document.querySelector('.accesories-preview')    
    let result = []
    let listItems = Array.from(ul.children)
    listItems.forEach((element, index) => {
      result.push(`${element.dataset.sku}x${element.dataset.quantity}`)
    })
    url = result.length > 0 ? `${url}/${result.join(',')}` : `${url}/no-accesories`
    url = `${url}/${this.installationOption}/`
    Turbolinks.visit(url)
  }
}