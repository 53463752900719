import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  open(event) {
    document.getElementById(event.target.dataset.modal).style.display = 'inline-block';
  }

  close(event) {
    document.getElementById(event.target.dataset.modal).style.display = 'none';
  }
}