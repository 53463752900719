import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'partialSpaceContainer','partialSpace']

  partial() {
    this.partialSpaceContainerTargets.forEach( container =>{
      container.classList.remove('d-none')
    })
  }

  next() {
    let partialSpace = this.partialSpaceTarget.value
    let url = `./partial,${partialSpace}/#step-quantity`
    let valid = validateSpace(partialSpace, this.partialSpaceTarget)
    console.log('url:',url)
    if (valid) { Turbolinks.visit(url) }
  }
}

let validateSpace = (space, target) => {
  space = Number(space)
  if ( space > target.max){
    alert(`Tu espacio (${space}) supera el ancho del maximo (${target.max})`)
    return false
  }
  if ( space < target.min){
    alert(`Tu espacio (${space}) supera el ancho minimo por puerta (${target.min})`)
    return false
  }
  return true
}