import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['doorKindOption', 'accordionOption', 'supportTypeOption', 'accordion', 'selectSupport' ]
  static outlets = ['preview']

  initialize() {
    this.doorKind = null
    this.supportType = null
    this.restrictions = {}
    this.doorKindSelect = 'con_riel'
  }

  connect() {
    this.doorKind = this.doorKindOptionTargets.find(target => target.classList.contains('selected'))?.dataset?.materialSelectedDoorKindParam
    const accordionOption = this.accordionOptionTargets.find(target => target.classList.contains('selected'))?.dataset?.materialSelectedDoorKindParam

    if (accordionOption === 'yes') {
      this.doorKind = 'accordion'
      this.supportType = 'ceiling'
      return
    }

    this.supportType = this.supportTypeOptionTargets.find(target => target.classList.contains('selected'))?.dataset?.materialSelectedSupportTypeParam
  }

  selectBackgroud(option) {
    let drawArea = document.getElementById("preview_selection")
    drawArea.classList.remove('wood_area','aluminium_area','accordion_area')
    drawArea.classList.add(`${option}`)
  }

  selectDoorKind(event) {
    // console.log(selectedDoorKind)
    // aluminium, wood
    // Marco Aluminio, Madera/MDF
    const selectedValue = event.target.value;
    var enValue = ''
    if(selectedValue === 'Marco Aluminio') {
      enValue = 'aluminium'
      this.selectAccordion({ target: { value: 'no' } })
      this.selectBackgroud('aluminium_area')
    } else if(selectedValue === 'Madera/MDF') {
      enValue = 'wood'
      this.selectBackgroud('wood_area')
    }
    this.doorKind = enValue
    console.log(this.doorKindSelect)
    if(this.doorKindSelect == 'con_riel') {
      this.selectSupportType({ target: { value: 'A piso' } })
    } else if(this.doorKindSelect === 'colgante') {
      this.selectSupportType({ target: { value: 'Colgante' } })
    }
    this.changeRestrictions()
  }

  selectAccordion(event) {
    var selectedValue = event.target.value;
    var enValue = ''
    if (selectedValue === 'Si') {
      enValue = 'yes'
    } else if(selectedValue === 'No') {
      enValue = 'no'
    }
    this.supportType = enValue === 'yes' ? 'ceiling' : null
    this.doorKind = enValue === 'yes' ? 'accordion' : this.doorKind
    if (enValue === 'yes') {
      this.selectBackgroud('accordion_area')
    }
    if (enValue !== 'yes') {
      this.selectBackgroud('wood_area')
    }
    this.changeRestrictions()
  }

  selectSupportType(event) {
    // 'floor', 'ceiling'
    var selectedValue = event.target.value
    var enValue = ''
    if(selectedValue === 'A piso') {
      enValue = 'floor'
    } else if(selectedValue === 'Colgante') {
      enValue = 'ceiling'
    }
    this.supportType = enValue
    this.changeRestrictions()
  }

  changeRestrictions() {
    const isAccordion = this.doorKind === 'accordion'
    let restrictions = { material: isAccordion ? 'wood' : this.doorKind, support: this.supportType, accordion: isAccordion }
    this.previewOutlet.updateRestrictions(restrictions)
  }

  next() {
    if (!this.doorKindSelect || !this.doorKind || !this.supportType) return

    Turbolinks.visit(`/projects/${this.doorKind}/${this.supportType}/`)
  }

  selectDoorType (e) {
    var selectedType = e.target.value;
    console.log('Debug ====>', selectedType)
    let doorKindOptionSelect = document.getElementById('material_doorKindOptions')
    let supportType = document.getElementById('supportTypeAccordion')
    this.doorKindSelect = selectedType
    this.doorKind = null;
    supportType.classList.remove('selected')

    document.getElementById('accordionDoorKind').classList.remove('d-none')
    document.getElementById('accordionDoorKindDivider').classList.remove('d-none')

    // document.getElementById('accordionSupport').classList.remove('d-none')
    // document.getElementById('accordionSupportDivider').classList.remove('d-none')

    doorKindOptionSelect.remove(1)
    doorKindOptionSelect.remove(2)
    doorKindOptionSelect.remove(1)

    doorKindOptionSelect.add(new Option('Marco Aluminio', 'Marco Aluminio'))
    doorKindOptionSelect.add(new Option('Madera/MDF', 'Madera/MDF'))

      if (selectedType === 'plegable') {
      doorKindOptionSelect.remove(2)
      supportType.classList.add('d-none')
      document.getElementById('accordionDoorKind').classList.add('d-none')
      document.getElementById('accordionDoorKindDivider').classList.add('d-none')

      // document.getElementById('accordionSupport').classList.add('d-none')
      // document.getElementById('accordionSupportDivider').classList.add('d-none')

      this.selectDoorKind({ target: { value: 'Madera/MDF' } })
      this.selectAccordion({ target: { value: 'Si' } })
      this.selectSupportType({ target: { value: 'Colgante' } })
    } else {
      supportType.classList.add('d-none')
    }
  }

}