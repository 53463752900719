import { Controller } from "@hotwired/stimulus"
let count = 0
export default class extends Controller {
  static targets = [ "collapse", "productList", 'arrow']
  
  collapse(event){
    this.collapseTargets.forEach((element, index) => {
      if(element.dataset.id != event.target.dataset.id){
        element.classList.remove('show-collapse')
        element.previousElementSibling.lastElementChild.classList.remove('rotate-img')
        return
      }
      element.classList.toggle("show-collapse")
      this.arrowTargets.forEach((a) => {
        if ( a.dataset.id == event.target.dataset.id) { 
          a.classList.toggle('rotate-img')
        }
      })
    })
  }
  
  add(event){
    let element = document.querySelector(`#${event.target.dataset.id}`)
    let ul = document.querySelector('.accesories-preview')
    if(!element) {return}
    if(element.value.replace(/[^\d]+/,'').length <= 0) {return}

    let  li = document.createElement("li");
    li.appendChild(document.createTextNode(`${element.dataset.name} x ${element.value.replace(/[^\d]+/,'')}`));
    li.dataset.sku = element.dataset.sku
    li.dataset.quantity = element.value
    li.dataset.action = 'click->step-accesories#delete'
    ul.appendChild(li);
  }

  validateQuantity(event){
    event.target.value= event.target.value.replace(/[^\d]+/,'')
  }

  delete(event){
    event.target.parentNode.removeChild(event.target)
  }

  next(){
    let ul = document.querySelector('.accesories-preview')
    
    let result = []
    let listItems = Array.from(ul.children)
    listItems.forEach((element, index) => {
      result.push(`${element.dataset.sku}x${element.dataset.quantity}`)
    })
    let url = './no-accesories/#step-additionals'
    console.log(result)
    if(result.length > 0) {
      url = `./${result.join(',')}/#step-additionals`
    }
    
    Turbolinks.visit(url)
  }
  
}
