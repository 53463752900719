import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  back (event) {
    let lastStep = event.target.dataset.laststep
    let currentUrl = window.location.pathname
    let urlArray = currentUrl.split('/')
    urlArray.pop()
    urlArray.pop()
    let url = `${urlArray.join('/')}/#step-${lastStep}`
    Turbolinks.visit(url)
  }
}
