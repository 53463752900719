import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['width', 'height', 'offset', 'coverOption',
                    'partialSpace', 'partialSpaceInput']
  static outlets = ['preview']

  connect(){
    // Space variables
    this.width = this.widthTarget
    this.height = this.heightTarget
    if(this.hasOffsetTarget) { this.offset = this.offsetTarget }

    // Cover variables
    this.partialSpace = this.partialSpaceTarget
    this.partialSpaceInput = this.partialSpaceInputTarget
    if(window.location.href.includes("accordion")) {
      this.selectBackgroud("accordion_area")
    }
    else {
      if(window.location.href.includes("wood")) {
        this.selectBackgroud("wood_area")
      }
      else {
        this.selectBackgroud("aluminium_area")
      }
    }
  }

  selectBackgroud(option) {
    let drawArea = document.getElementById("preview_selection")
    drawArea.classList.remove('wood_area','aluminium_area','accordion_area')
    drawArea.classList.add(`${option}`)
  }

  selectCoverType({ target: { value } }){
    this.partialSpaceField(value);
  }

  partialSpaceField(value){
    if(value === 'No'){
      this.partialSpace.classList.remove('d-none')
    }
    else {
      this.partialSpace.classList.add('d-none')
    }
  }

  next(){
    if(this.spaceValidation() && this.coverValidation()){
      Turbolinks.visit(this.buildURI())
    }
  }

  spaceValidation(){
    let isNumeric = /^\d+$/.test(Number(this.width.value)) || /^\d+$/.test(Number(this.height.value))
    let hasNegativeValue = Number(this.width.value) < 0 || Number(this.height.value) < 0

    if (!isNumeric || hasNegativeValue ) {
      alert('Ingresa ancho y alto validos para continuar.')
      return false
    } 
    if(Number(this.width.value) > Number(this.width.max)){
      alert('Tu vano supera el ancho máximo de las puertas.')
      return false
    }
    if(Number(this.height.value) > Number(this.height.max)){
      alert('Tu vano supera el alto máximo de las puertas.')
      return false
    }
    if(Number(this.width.value) < Number(this.width.min)){
      alert('Tu vano tiene un ancho inferior al minimo')
      return false
    }
    if(Number(this.height.value) < Number(this.height.min)){
      alert('Tu vano tiene un alto inferior al minimo')
      return false
    }
    if(this.hasOffsetTarget && Number(this.offset.value) < Number(this.offset.min)){
      alert(`Despeje debe de tener un mínimo de ${Number(this.offset.min)} milímetros`)
      return false
    }
    if(this.hasOffsetTarget && Number(this.offset.value) > Number(this.offset.max)){
      alert(`Tu despeje supera el maximo de ${Number(this.offset.max)} milímetros`)
      return false
    }
    return true
  }

  coverValidation(){
    if(this.coverOptionTarget.value === undefined){ 
      alert('Por favor, elija un tipo de cobertura')
      return false
    }

    if(this.coverOptionTarget.value === 'No') {
      if ( Number(this.partialSpaceInput.value) > Number(this.width.value)){
        alert(`Tu espacio (${Number(this.partialSpaceInput.value)}) supera el ancho del maximo (${Number(this.width.value)})`)
        return false
      }
      if ( Number(this.partialSpaceInput.value) < Number(this.partialSpaceInput.min)){
        alert(`Tu espacio (${Number(this.partialSpaceInput.value)}) supera el ancho minimo por puerta (${Number(this.partialSpaceInput.min)})`)
        return false
      }
    }

    return true
  }

  changeRestrictions() {
    const restrictions = { 
      partialSpace: this.partialSpaceInput.value,
      space: `${this.width.value}x${this.height.value}${this.hasOffsetTarget ? ',' + this.offset.value : ''}`,
      spaceHeight: this.height.value,
      spaceOffset: this.hasOffsetTarget && this.offset.value,
      spaceWidth: this.width.value,
      cover: this.coverOptionTarget.value === 'No' ? 'partial' : 'full'
     }
    this.previewOutlet.updateRestrictions(restrictions)
  }

  buildURI(){
    let offset = this.offset === undefined ? 0 : this.offset.value
    if(this.coverOptionTarget.value === 'No') {
      return `./${this.width.value}x${this.height.value},${offset}/partial,${this.partialSpaceInput.value}/`
    }
    else {
      return `./${this.width.value}x${this.height.value},${offset}/full/`
    }
  }
}
