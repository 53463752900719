
import { Controller } from "@hotwired/stimulus"

let doorQuantity = 0
export default class extends Controller {
  static targets = [ "symmetry" , "quantityButton", "material", 'width']
  quantity(event) {
    let quantity = event.target
    let url = ''
    this.quantityButtonTargets.forEach((button) => {
      button.classList.remove('selected')
    })
    doorQuantity = +quantity.dataset.quantity
    quantity.classList.add('selected')
    if (doorQuantity > 1) {
      this.symmetryTarget.classList.remove('d-none')
    } else {
      let symmetry = doorQuantity == 1 ? "symetric" : "asymetric"
      if(symmetry == 'symetric') {
        url = stepUrl(doorQuantity,symmetry,'yes', this.symmetryTarget)
      }else {
        url = `./${doorQuantity}-${symmetry}/#step-size`
      }
      Turbolinks.visit(url)
    }
  }
  symmetry(event){
    let answer = event.target.dataset.answer
    let symmetry = answer == 'yes' ? "symetric" : "asymetric"
    history.replaceState(null, null, window.location.href.split('#')[0])
    let url = stepUrl(doorQuantity,symmetry,answer, this.symmetryTarget)
    Turbolinks.visit(url) 
  }
}
let stepUrl = (doorQuantity,symmetry,answer, targets) => {
  let url = `./${doorQuantity}-${symmetry}/`
  let nextStep = ""
  let doorMeasurements = calculateSymetryDoors(symmetry,doorQuantity, targets)
  if (answer == 'yes') {
    nextStep = `${doorMeasurements}/#step-type`
    url = `${url}${nextStep}`
  } else {
    nextStep = '#step-size'
    url = `${url}${nextStep}`
  }
  return url
}

let calculateSymetryDoors = (symmetryValue, doorQuantity, targets) => {
  let maxSize = targets.dataset.maxSize
  let dimensions = []
  if ( symmetryValue == 'symetric' ){
    let doorWidth = Math.trunc(maxSize / doorQuantity)
    for (let i = 0; i < doorQuantity; i++){
      dimensions.push(doorWidth)
    }
  }
  return dimensions.join(',')
}