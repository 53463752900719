document.addEventListener('turbolinks:load', () => {
  let accordions = document.querySelectorAll('.accordion-toggle')
  if(accordions){
    accordions.forEach((accordion) => {
      let card_header = accordion.getElementsByClassName('card-header')[0]
      let button = accordion.getElementsByTagName('span')[0]
      let id_target = button.getAttribute('data-target')
      let arrow_icon = card_header.getElementsByClassName(`${id_target}_arrow`)[0]
      card_header.addEventListener('click', () => {
        if(arrow_icon) arrow_icon.classList.toggle('rotate_arrow')
        let collapse = document.getElementById(id_target)
        collapse.classList.toggle('show')      
      })
    })
  }
})
