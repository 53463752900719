import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "quantityButton",
    "symetryButton",
    "typeButton",
    "doorWidthSection",
    "doorTypeSection",
    "doorWidthPlaceholder",
    "doorWidthField",
    "doorTypePlaceholder",
    "doorTypeField",
    "width",
  ];
  static outlets = ["preview"];
  static values = {
    doorMaterial: String,
  };

  connect() {
    this.quantityButtons = this.quantityButtonTargets;
    this.symetryButtons = this.symetryButtonTargets;
    this.doorWidthSection = this.doorWidthSectionTarget;
    this.doorTypeSection = this.doorTypeSectionTarget;
    this.widthPlaceHolder = this.doorWidthPlaceholderTarget;
    this.typePlaceHolder = this.doorTypePlaceholderTarget;
    this.widthField = this.doorWidthFieldTarget;
    this.typeField = this.doorTypeFieldTarget;
    this.width = this.widthTarget;
    this.doorTypes = {};
    if (window.location.href.includes("accordion")) {
      this.selectBackgroud("accordion_area");
    } else {
      if (window.location.href.includes("wood")) {
        this.selectBackgroud("wood_area");
      } else {
        this.selectBackgroud("aluminium_area");
      }
    }
  }

  selectBackgroud(option) {
    let drawArea = document.getElementById("preview_selection");
    drawArea.classList.remove("wood_area", "aluminium_area", "accordion_area");
    drawArea.classList.add(`${option}`);
  }

  doorQuantity(event) {
    this.clickedQuantity = event.target;
    this.selectedQuantity = Number(event.target.dataset.quantity);
    this.unselectTypeButton();
    this.unselectSymetryButton();
    this.selectQuantityButton();
    this.enableDoorOptions();

    this.changeRestrictions();
  }

  doorQuantitySelect(event) {
    this.selectedQuantity = Number(event.target.value);
    this.enableDoorOptions();
    this.changeRestrictions();
  }

  doorSymetry(event) {
    this.clickedSymetry = event.target;
    let index = this.clickedSymetry.selectedIndex;
    this.selectedSymetry =
      this.clickedSymetry.options[index].text.toLowerCase() === "no" ? false : true;
    // this.selectSymetryButton()
    if (this.selectedSymetry) {
      this.calculateDoorWidths();
    } else {
      this.clearDoorWidths();
    }

    this.changeRestrictions();
  }

  doorTypeSelect(event) {
    let selectedValue = event.target.value;
    let door_number = event.target.dataset.door;
    if (selectedValue === "Fija") {
     if (door_number !== undefined) {
      this.doorTypes[`door_${door_number}_type`] = "fixed";
      this.changeRestrictions();
    }
    } else if (selectedValue === "Móvil") {
      if (door_number !== undefined) {
        this.doorTypes[`door_${door_number}_type`] = "mobile";
        this.changeRestrictions();
      }
    }
    this.changeRestrictions();
  }

  doorType({ target }) {
    let clickedOption = target;
    this.doorTypes[`door_${clickedOption.dataset.door}_type`] =
      clickedOption.dataset.type;
    let brotherButton = this.typeButtonTargets.filter(
      (btn) =>
        btn.dataset.door === clickedOption.dataset.door &&
        btn.dataset.type !== clickedOption.dataset.type
    );
    this.selectTypeButton(clickedOption, brotherButton[0]);

    this.changeRestrictions();
  }

  selectSymetryButton() {
    this.symetryButtons.forEach((symetryButtons) => {
      symetryButtons.classList.remove("btn-primary");
      symetryButtons.classList.add("btn-primary-outline");
    });
    this.clickedSymetry.classList.add("btn-primary");
  }

  selectTypeButton(clickedButton, brotherButton) {
    clickedButton.classList.add("selected");
    brotherButton.classList.remove("selected");
  }

  unselectSymetryButton() {
    this.symetryButtons.forEach((symetryButtons) => {
      symetryButtons.classList.remove("btn-primary");
      symetryButtons.classList.add("btn-primary-outline");
    });
  }

  unselectTypeButton() {
    this.doorTypes = {};
    this.typeButtonTargets.forEach((typeButton) => {
      typeButton.classList.remove("btn-primary");
      typeButton.classList.add("btn-primary-outline");
    });
  }

  selectQuantityButton() {
    this.quantityButtons.forEach((quantityButton) => {
      quantityButton.classList.remove("btn-primary");
      quantityButton.classList.add("btn-primary-outline");
    });
    this.clickedQuantity.classList.add("btn-primary");
  }

  calculateDoorWidths() {
    if (this.selectedQuantity === undefined) {
      return;
    }
    let doorSize = Math.trunc(
      Number(this.width.dataset.maxWidth) / this.selectedQuantity
    );
    this.doorWidthFieldTargets.forEach((doorWidthField) => {
      if (!Array.from(doorWidthField.classList).includes("d-none")) {
        let doorInput = doorWidthField.children[1].firstChild.firstChild;
        doorInput.value = doorSize;
        doorInput.classList.add("disabled");
      }
    });
  }

  changeRestrictions() {
    const doorRestrictions = {};
    this.widthTargets.forEach((doorWidthField, index) => {
      // skipping first because there index 0 is template
      if (index !== 0)
        doorRestrictions[`door_${index}_width`] = doorWidthField.value;
    });
    const restrictions = {
      quantity: this.selectedQuantity,
      symmetry: this.selectedSymetry ? "symetric" : "asymetric",
      doorAmount: this.selectedQuantity,
      ...doorRestrictions,
      ...this.doorTypes,
    };

    this.previewOutlet.updateRestrictions(restrictions);
  }

  clearDoorWidths() {
    this.doorWidthFieldTargets.forEach((doorWidthField) => {
      if (!Array.from(doorWidthField.classList).includes("d-none")) {
        let doorInput = doorWidthField.children[1].firstChild.firstChild;
        doorInput.value = 0;
        doorInput.classList.remove("disabled");
      }
    });
  }

  enableDoorOptions() {
    this.widthPlaceHolder.classList.add("d-none");
    this.typePlaceHolder.classList.add("d-none");

    this.doorWidthFieldTargets.forEach((doorWidthField) => {
      if (!Array.from(doorWidthField.classList).includes("d-none")) {
        doorWidthField.remove();
      }
    });
    this.doorTypeFieldTargets.forEach((doorTypeField) => {
      if (!Array.from(doorTypeField.classList).includes("d-none")) {
        doorTypeField.remove();
      }
    });

    Array.from({ length: this.selectedQuantity }, (x, i) => {
      let newWidthField = this.widthField.cloneNode(true);
      newWidthField.classList.remove("d-none");
      newWidthField.firstChild.firstChild.innerHTML = `Puerta ${i + 1}`;
      this.doorWidthSection.append(newWidthField);
    });
    Array.from({ length: this.selectedQuantity }, (x, i) => {
      const newTypeField = this.typeField.cloneNode(true);
      newTypeField.classList.remove("d-none");
      newTypeField.firstChild.firstChild.innerHTML = `Puerta ${i + 1}`;
      this.doorTypeSection.append(newTypeField);
      newTypeField.firstChild.firstChild.dataset.door = i + 1;
      newTypeField.children[1].firstChild.dataset.door = i + 1;
      const doorSelect = newTypeField.children[1].firstChild;

      doorSelect.remove(1);
      doorSelect.remove(2);
      doorSelect.remove(1);

      if (window.location.href.includes("accordion")) {
        // Comment out this becuse we need this as initially selected
        // doorSelect.add(new Option("Móvil"));
      } else {
        doorSelect.add(new Option("Fija"));
        doorSelect.add(new Option("Móvil"));
      }

      // if(this.doorMaterialValue === 'wood') {
      //   doorSelect.remove(1)
      //   this.doorTypeSelect({
      //     target: {
      //       value: 'Móvil',
      //       dataset: {
      //         door: i + 1
      //       }
      //     }
      //   })
      // }
    });
  }

  doorSizeValidations(doorInput, totalWidth) {
    let hasNegativeValue = Number(doorInput.value) < 0;
    let isNumeric = /^\d+$/.test(doorInput.value);
    let isInRange = Number(totalWidth) <= Number(this.width.dataset.maxWidth);
    let singleMaxSize = Number(doorInput.value) <= Number(doorInput.max);
    let singleMinSize = Number(doorInput.value) >= Number(doorInput.min);

    if (!isNumeric || hasNegativeValue) {
      alert("Ingresa valores de ancho validos para continuar.");
      return false;
    }
    if (!isInRange) {
      alert(
        `Ancho total de las puertas (${totalWidth}) superan ancho maximo del vano (${this.width.dataset.maxWidth})`
      );
      return false;
    }
    if (!singleMaxSize) {
      alert(
        `Ancho de la puerta (${doorInput.value}) supera el maximo (${doorInput.max}), cambie las medidas de la puerta`
      );
      return false;
    }
    if (!singleMinSize) {
      alert(
        `Ancho de la puerta (${doorInput.value}) es inferior al minimo (${doorInput.min}), cambie las medidas de la puerta`
      );
      return false;
    }
    return true;
  }

  next() {
    let URI = this.buildURI();
    if (URI) {
      Turbolinks.visit(URI);
    }
  }

  buildURI() {
    let quantity = this.selectedQuantity;
    let symetry = this.selectedSymetry ? "symetric" : "asymetric";
    let doorSizes = [];
    let doorTypes = [];
    let validation = [];

    if(window.location.href.includes("accordion")) {
      for(let i = 0; i < this.selectedQuantity; i++) {
        doorTypes.push("mobile");
      }
    } else {
       Array.from({ length: this.selectedQuantity }, (x, i) => {
      doorTypes.push(this.doorTypes[`door_${i + 1}_type`]);
    });
    }

    this.doorWidthFieldTargets.forEach((doorWidthField) => {
      if (!Array.from(doorWidthField.classList).includes("d-none")) {
        let doorInput = doorWidthField.children[1].firstChild.firstChild;
        doorSizes.push(doorInput.value);
      }
    });

    let totalWidth = doorSizes.reduce((acc, next) => acc + Number(next), 0);

    this.doorWidthFieldTargets.forEach((doorWidthField) => {
      if (!Array.from(doorWidthField.classList).includes("d-none")) {
        let doorInput = doorWidthField.children[1].firstChild.firstChild;
        validation.push(this.doorSizeValidations(doorInput, totalWidth));
      }
    });

    if (validation.includes(false) || quantity === undefined || symetry === undefined || doorSizes.includes("") || doorSizes.length === 0 || doorTypes.includes(undefined) || doorTypes.includes("")) {
      alert("Por favor ingresa todas las medidas de las puertas");
      return false;
    } else {
      if(doorTypes.length === 1 && doorTypes[0] === undefined) {
        doorTypes.push('mobile')
      }
      return `./${quantity}-${symetry}/${doorSizes.join(",")}/${doorTypes.join(
        ","
      )}/`;
    }
  }
}
